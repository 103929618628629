import React, { useCallback, useEffect, useRef, useState } from "react";
import { useFavContext } from "../../context/FavContextProvider";
import AdminView from "../adminview/AdminView";
import { DealerShip } from "../../datamodels/profile";

const SwitchDealer: React.FC<{
  codes: string[];
  dealerShipList: DealerShip[];
  setCanOpenDealerCodeMenu: any;
}> = ({ codes, dealerShipList, setCanOpenDealerCodeMenu }) => {
  const { setDealerCode, code, profile } = useFavContext();
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const handleDealerChange = (selectedCode: string) => {
    setDealerCode(selectedCode);
    setCanOpenDealerCodeMenu(false);
  };

  useEffect(() => {
    setSelectedIndex(codes.indexOf(code));
  }, [code]);

  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((event: MouseEvent) => {
    if (ref.current && !ref.current.contains(event.target as Node)) {
      setCanOpenDealerCodeMenu(() => false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  const renderSwitchDealer = () => {
    return (
      <>
        <div className="font-FordF1Regular bg-[#F3F8FE] text-[#00095B] px-5 py-2.5">
          <h3>Switch Dealership</h3>
        </div>
        {codes?.map((salesCode, index) => (
          <>
            <div
              className="grid grid-flow-col auto-cols-max gap-4 items-center font-FordF1Regular text-[#00095B] px-5 py-2.5  hover:bg-slate-200 cursor-pointer"
              onClick={() => handleDealerChange(salesCode)}
            >
              <h3 className="text-left">
                {salesCode}&nbsp;-&nbsp;
                {
                  dealerShipList?.find(
                    ({ dealerCode }) => dealerCode === salesCode
                  )?.dealerName
                }
              </h3>
              <div>
                {selectedIndex === index ? (
                  <i className="pi pi-check-circle font-FordF1Light"></i>
                ) : (
                  ""
                )}
              </div>
            </div>
            {index !== codes.length - 1 && <hr />}
          </>
        ))}
      </>
    );
  };

  const renderSetDealer = () => {
    return (
      <>
        <div className="font-FordF1Regular bg-[#F3F8FE] text-[#00095B] px-5 py-2.5">
          <h3>Set Dealership</h3>
        </div>
        <div className="p-4">
          <AdminView setCanOpenDealerCodeMenu={setCanOpenDealerCodeMenu} />
        </div>
      </>
    );
  };

  return (
    <div
      ref={ref}
      className="border rounded text-black text-left bg-white z-50"
    >
      {profile?.fordUserType.toLowerCase().includes("employee")
        ? renderSetDealer()
        : renderSwitchDealer()}
    </div>
  );
};

export default SwitchDealer;
