import React from "react";
import car from "../../assets/images/dribbble.gif";
import "./CarLoading.css";

const CarLoading: React.FC = () => {
  return (
    <div className="w-[200px] mx-auto">
      <img src={car} className="loading-car" width={50} />
    </div>
  );
};

export default CarLoading;
