import "./App.css";
import { FavContextProvider } from "./context/FavContextProvider";
import AppRouter from "./routes/AppRouter";
import "primeicons/primeicons.css";

function App() {
  return (
    <div className="App">
      <FavContextProvider>
        <AppRouter />
      </FavContextProvider>
    </div>
  );
}

export default App;
