import { useQuery } from "@tanstack/react-query";
import ProfileObject from "../datamodels/profile";
import { IFeedBackModel } from "../datamodels/FeedBackModel";
import api from "../store/api";
import { GetBearerToken } from "../utils/GetBearerToken";
const baseURL = "/api/v1/dealer/feedback";

export const useFeedBackData = (profile: ProfileObject | undefined) => {
  const {
    data: FeedBackData,
    isLoading: loading,
    error,
  } = useQuery<IFeedBackModel[]>({
    queryKey: ["feedbackData", profile?.uid],
    queryFn: async () => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.get(`${baseURL}/getAll`, {
          headers: {
            Authorization: `Bearer ${bearer_token}`,
          },
        });
        return response?.data?.data?.dealerFeedBacks;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    enabled: !!profile?.uid,
    staleTime: 1000 * 60 * 60 * 4,
  });
  return { FeedBackData, loading, error };
};
