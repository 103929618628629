import AdminOnlyRoute from "./AdminOnlyRoute";
import ProtectedRoute from "./ProtectedRoute";

type RouteHandlerProps = {
  isProtected: boolean;
  adminOnly: boolean;
  element: React.ReactNode;
};

const RouteHandler: React.FC<RouteHandlerProps> = ({
  isProtected,
  adminOnly,
  element,
}) => {
  if (!element) {
    return null;
  }

  if (isProtected) {
    return <ProtectedRoute>{element}</ProtectedRoute>;
  } else if (adminOnly) {
    return <AdminOnlyRoute>{element}</AdminOnlyRoute>;
  } else {
    return <>{element}</>;
  }
};

export default RouteHandler;
