import React, { useState } from "react";
import { IFeedBackModel } from "../../datamodels/FeedBackModel";
import { useFavContext } from "../../context/FavContextProvider";
import { ProgressSpinner } from "primereact/progressspinner";
import { GetBearerToken } from "../../utils/GetBearerToken";
import api from "../../store/api";
const baseURL = "/api/v1/dealer/feedback";
interface FeedbackProps {
  closeFeedback?: () => void;
}

const Feedback: React.FC<FeedbackProps> = ({ closeFeedback }) => {
  let btnKey = 1;
  const [feedBack, setFeedBack] = React.useState<IFeedBackModel>({
    userId: "",
    likes: "",
    recommendations: "",
    rating: 10,
    mobile: "",
    email: "",
  });
  const [contactForFeedback, setContactForFeedback] = useState<boolean>(true);
  const { profile, setViewFeedBack } = useFavContext();

  const [successBanner, setSuccessBanner] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  /* istanbul ignore next */
  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let feedbackData = { ...feedBack, userId: profile?.uid };
    if (!contactForFeedback) {
      delete feedbackData.mobile;
      delete feedbackData.email;
    }
    try {
      setIsLoading(true);
      const bearer_token = await GetBearerToken();
      const response = await api.post(`${baseURL}/add`, feedbackData, {
        headers: {
          Authorization: `Bearer ${bearer_token}`,
        },
      });
      setSuccessBanner(true);
      return response.data;
    } catch (error: any) {
      throw new Error(error.response.message);
    } finally {
      setIsLoading(false);
    }
  };
  const renderFeedback = () => {
    if (successBanner) {
      return (
        <div className="w-full md:w-fit md:mx-auto my-2 p-3 rounded-xl bg-fordBlue !text-white">
          <div className="w-full flex justify-end">
            <svg
              width="24"
              height="24"
              viewBox="0 0 48 48"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={() => setViewFeedBack(false)}
              className="cursor-pointer"
            >
              <path
                d="M25.68 24L36 34.32L34.32 36L24 25.68L13.68 36L12 34.32L22.32 24L12 13.68L13.68 12L24 22.32L34.32 12L36 13.68L25.68 24Z"
                fill="white"
              />
            </svg>
          </div>
          <h1 className="font-FordF1Bold my-5">Thank you for your feedback</h1>
          <p className="font-FordF1Medium">
            We appreciate your feedback and will use it to improve our service.
          </p>
          <p>
            Submit Another Feedback{" "}
            <span
              className="underline underline-offset-1 cursor-pointer"
              onClick={() => setSuccessBanner(false)}
            >
              Click Here
            </span>
          </p>
        </div>
      );
    }
    return (
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-1000 p-3 rounded-lg">
        <div className="absolute top-0 left-0 w-full h-full bg-black opacity-70"></div>
        <div className="relative bg-white ">
          <div className=" w-full md:w-fit md:mx-auto rounded-xl p-3">
            {isLoading ? (
              <ProgressSpinner data-testID="progressBar" />
            ) : (
              <form action="#" method="POST" onSubmit={handleSubmit}>
                <div className="flex justify-between mt-5 font-FordF1Medium">
                  <h1 className="text-[#00142E] text-[24px] text-left">
                    Give Feedback
                  </h1>
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 48 48"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    onClick={() => setViewFeedBack(false)}
                    className="cursor-pointer"
                  >
                    <path
                      d="M25.68 24L36 34.32L34.32 36L24 25.68L13.68 36L12 34.32L22.32 24L12 13.68L13.68 12L24 22.32L34.32 12L36 13.68L25.68 24Z"
                      fill="black"
                    />
                  </svg>
                </div>
                <h2 className="text-left text-[16px] font-FordF1Regular my-3">
                  Your feedback helps us build a useful tool.
                </h2>
                <div className="my-2">
                  <label
                    htmlFor="likes"
                    className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                  >
                    What do you like about your experience?
                  </label>
                  <input
                    placeholder="Brief Description about likes"
                    className="border my-1 p-2 rounded-xl w-full text-left text-[#7C838D]"
                    id="likes"
                    required
                    onChange={(e) =>
                      setFeedBack((data) => ({
                        ...data,
                        likes: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="my-2">
                  <label
                    htmlFor="subject"
                    className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                  >
                    What would make this tool more useful?
                  </label>
                  <input
                    placeholder="Brief Description about recommendations"
                    className="border my-1 p-2 rounded-xl w-full text-left text-[#7C838D]"
                    id="subject"
                    required
                    onChange={(e) => {
                      setFeedBack((data) => ({
                        ...data,
                        recommendations: e.target.value,
                      }));
                    }}
                  />
                </div>
                <div className="my-2">
                  <label
                    htmlFor="rating"
                    className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                  >
                    How useful is this tool?
                  </label>
                  {[...Array(11)].map((_, value) => (
                    <button
                      key={btnKey++}
                      type="button"
                      className={`border h-[40px] w-[43.6px] rounded-lg my-1 mx-1 text-[#7C838D] font-FordF1Regular text-[14px] ${
                        feedBack.rating === value
                          ? "bg-fordGrabber text-white"
                          : "hover:bg-fordGrabber hover:text-white"
                      }`}
                      onClick={() =>
                        setFeedBack((data) => ({ ...data, rating: value }))
                      }
                    >
                      {value}
                    </button>
                  ))}
                </div>
                <div className="my-2">
                  <label
                    htmlFor="contactForFeedback"
                    className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                  >
                    Would you like to be contacted for more feedback?
                  </label>
                  <div className="text-left">
                    <button
                      type="button"
                      className={`border h-[40px] w-[43.6px] rounded-lg my-1 mx-1 text-[#7C838D] font-FordF1Regular text-[14px] hover:bg-fordGrabber ${
                        contactForFeedback ? "bg-fordGrabber text-white" : ""
                      }`}
                      onClick={() => setContactForFeedback(true)}
                    >
                      Yes
                    </button>
                    <button
                      type="button"
                      className={`border h-[40px] w-[43.6px] rounded-lg my-1 mx-1 text-[#7C838D] font-FordF1Regular text-[14px] hover:bg-fordGrabber ${
                        !contactForFeedback ? "bg-fordGrabber text-white" : ""
                      }`}
                      onClick={() => setContactForFeedback(false)}
                    >
                      No
                    </button>
                  </div>
                  {contactForFeedback && (
                    <div>
                      <label
                        htmlFor="phoneNumber"
                        className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                      >
                        Phone Number:
                      </label>
                      <input
                        type="tel"
                        className="border my-1 p-2 rounded-xl w-full text-left text-[#7C838D]"
                        id="phoneNumber"
                        required={contactForFeedback}
                        placeholder="Please enter Phone Number"
                        value={feedBack.mobile}
                        onChange={(e) => {
                          const regex = /^[0-9\W]*$/;
                          if (regex.test(e.target.value)) {
                            setFeedBack((data) => ({
                              ...data,
                              mobile: e.target.value,
                            }));
                          }
                        }}
                      />
                      <label
                        htmlFor="email"
                        className="font-FordF1Medium block text-left text-[14px] text-[#333333]"
                      >
                        Email Address:
                      </label>
                      <input
                        type="email"
                        className="border my-1 p-2 rounded-xl w-full text-left text-[#7C838D]"
                        id="email"
                        required={contactForFeedback}
                        placeholder="Please enter Email address"
                        onChange={(e) =>
                          setFeedBack((data) => ({
                            ...data,
                            email: e.target.value,
                          }))
                        }
                      />
                    </div>
                  )}
                </div>
                <div className="my-2 text-left">
                  <button
                    type="submit"
                    className="w-[163px] min-h-[40px] bg-[#066FEF] font-FordF1SemiBold rounded-full my-1 text-white text-[12px] p-2"
                  >
                    Submit Feedback
                  </button>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>
    );
  };
  return renderFeedback();
};

export default Feedback;
