import { Navigate } from "react-router-dom";

export function OAuthRedirect() {
  const searchParams = new URLSearchParams(
    window.location.hash.replace("#", "")
  );

  const accessToken = searchParams.get("access_token");
  const expiresIn = searchParams.get("expires_in") || "";
  const idToken = searchParams.get("id_token");
  const expirationTime = String(
    new Date().getTime() + parseInt(expiresIn, 10) * 1000
  );

  sessionStorage.setItem("access_token", accessToken || "");
  sessionStorage.setItem("expires_in", expiresIn || "");
  sessionStorage.setItem("id_token", idToken || "");
  sessionStorage.setItem("expiration_time", expirationTime);

  return <Navigate to="/" />;
}
