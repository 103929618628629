import { DealerShip } from "../datamodels/profile";

export const getWidth = (userType: string) => {
  switch (userType) {
    case "lincoln":
      return 145;
    case "ford":
    default:
      return 95;
  }
};

export const getName = (
  dealerShip: DealerShip,
  name: string,
  isShowDealerCode: boolean
) => {
  const { dealerCode, dealerName } = dealerShip || "-";
  if (dealerShip) {
    return isShowDealerCode ? `${dealerCode} - ${dealerName}` : `${dealerName}`;
  } else if (name) {
    return name;
  } else {
    return "Quirk Ford";
  }
};
