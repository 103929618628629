import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { track } from "@amplitude/analytics-browser";
import { useFavContext } from "../../context/FavContextProvider";
import "./NavBar.css";
import { Constants } from "../../Constants";
import { DealerShip } from "../../datamodels/profile";
import { getName } from "../../utils/CommonUtils";

const NavBar: React.FC<{
  navType: "MOBILE" | "DESKTOP";
  setCanOpenSideNav: any;
}> = ({ navType, setCanOpenSideNav }) => {
  const { profile, code, dealerShipList, name, salesCodes } = useFavContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const trackingLocation = pathname === "/" ? "landing page" : pathname;
  const [logoutMenu, setLogoutMenu] = useState(false);

  const dealerShip = dealerShipList?.find(
    ({ dealerCode }) => dealerCode === code
  );
  const userType = code[0].toLowerCase() === "f" ? "ford" : "lincoln";

  const handleLogout = () => {
    window.sessionStorage.clear();
    window.location.href = `${process.env.REACT_APP_LOGOUT_URL}`;
  };

  const handleNavClose = () => {
    setCanOpenSideNav(false);
  };

  if (navType === "DESKTOP") {
    return (
      <nav>
        {profile ? (
          <div className="hidden md:flex justify-center font-FordF1SemiBold text-xs sm:text-[14px] text-[#4D4D4D] w-full bg-[#FFF] p-1 border-y-2  border-[#808080] border-opacity-65">
            {[
              ["Dashboard", "/"],
              ["Sales & Inventory", Constants.SALES_ROUTE],
              ["Parts & Service", Constants.PARTS_SERVICE_ROUTE],
              ["Finance & Insurance", Constants.FINANCE_INSURANCE_ROUTE],
              ["Subscriptions", Constants.SUBSCRIPTIONS],
            ].map(([title, url]) => (
              <button
                className={`py-3 px-0 mx-2 cursor-pointer rounded md:w-[15%]  ${
                  trackingLocation === url ||
                  (trackingLocation === "landing page" && url === "/")
                    ? "text-[#066FEF]"
                    : ""
                }`}
                onClick={() => {
                  track("main nav - open", {
                    location: trackingLocation,
                    name: title,
                  });
                  title === "Privacy Policy"
                    ? window.open(url, "_blank")
                    : navigate(url);
                }}
                key={title}
              >
                {title}
              </button>
            ))}
          </div>
        ) : (
          <></>
        )}
      </nav>
    );
  }

  return (
    <div className="fixed z-10 top-0 right-0 h-screen overflow-scroll w-full sm:w-72 bg-[#fff] text-fordBlue">
      <button className="fixed top-4 right-4" onClick={handleNavClose}>
        <i className="pi pi-times" style={{ color: "#00095b" }}></i>
      </button>
      <ul className="flex flex-col gap-2 px-8 py-4 text-left">
        {[
          ["Home", "/"],
          ["Sales & Inventory", Constants.SALES_ROUTE],
          ["Parts & Service", Constants.PARTS_SERVICE_ROUTE],
          ["Finance & Insurance", Constants.FINANCE_INSURANCE_ROUTE],
          ["Subscriptions", Constants.SUBSCRIPTIONS],
        ].map(([title, url]) => {
          return (
            <button
              className={`py-3 px-0 mx-2 cursor-pointer rounded text-left font-FordF1SemiBold text-xs ${
                trackingLocation === url ||
                (trackingLocation === "landing page" && url === "/")
                  ? "text-[#066FEF]"
                  : ""
              }`}
              onClick={() => {
                track("main nav - open", {
                  location: trackingLocation,
                  name: title,
                });
                setCanOpenSideNav(() => false);
                title === "Privacy Policy"
                  ? window.open(url, "_blank")
                  : navigate(url);
              }}
              key={title}
            >
              {title}
            </button>
          );
        })}
      </ul>
      <hr />
      <div
        className="flex p-4 items-center justify-around"
        onClick={() => setLogoutMenu((prevLogoutMenu) => !prevLogoutMenu)}
      >
        <div>
          <i className="pi pi-user" style={{ fontSize: "2rem" }}></i>
        </div>
        <div className="text-left text-fordBlue">
          <h3 className="font-semibold text-xs">
            {getName(dealerShip as DealerShip, name, true)}
          </h3>
          <h3 className="font-thin capitalize text-xs">{userType} Dealer</h3>
        </div>
        <div>
          {!logoutMenu ? (
            <i
              className="pi pi-angle-down font-FordF1Light"
              style={{ fontSize: "1.5rem" }}
            ></i>
          ) : (
            <i
              className="pi pi-angle-up font-FordF1Light"
              style={{ fontSize: "1.5rem" }}
            ></i>
          )}
        </div>
      </div>
      <>
        <button className="flex px-8 py-4 items-center" onClick={handleLogout}>
          <i
            className="pi pi-sign-out font-FordF1Light"
            style={{ fontSize: "1.5rem" }}
          ></i>
          <h3 className="font-semibold text-left font-FordF1Regular text-xs px-4">
            Logout
          </h3>
        </button>
      </>
    </div>
  );
};
export default NavBar;
