import { IDealerModel } from "../datamodels/dealerModel";

export const CreateOption = (data: IDealerModel[] | null) => {
  if (!data) return [];

  return data?.map((dealer) => ({
    value: dealer.ebizDlrNo,
    label: `${dealer.ebizDlrNo} - ${dealer.ebizDealerTradeName}`,
  }));
};
