import { useNavigate } from "react-router";
import React, { useEffect, useState } from "react";
import { useFavContext } from "../../context/FavContextProvider";
import fordLogo from "./../../../src/assets/images/ford-logo-blue.svg";
import lincolnLogo from "./../../../src/assets/images/lincoln-logo-dark.svg";
import "./Header.css";
import Logout from "../logout/Logout";
import { getWidth, getName } from "../../utils/CommonUtils";
import SwitchDealer from "../switch-dealer/SwitchDealer";
import { DealerShip } from "../../datamodels/profile";
import NavBar from "../navbar/NavBar";

const Header: React.FC = () => {
  const { profile, code, salesCodes, dealerShipList, name } = useFavContext();
  const [logoutMenu, setLogoutMenu] = useState(false);
  const [canOpenDealerCodeMenu, setDealerCodeMenu] = useState(false);
  const [canOpenSideNav, setCanOpenSideNav] = useState(false);
  const [selectedBrand, setSelectedBrand] = useState("Ford");
  const Navigate = useNavigate();

  useEffect(() => {
    if (code) {
      const userType = code[0].toLowerCase() === "f" ? "Ford" : "Lincoln";
      setSelectedBrand(userType);
    }
  }, [code]);

  const renderLogo = () => {
    const userType = code[0].toLowerCase() === "f" ? "ford" : "lincoln";

    return (
      <button
        className="flex cursor-pointer"
        onClick={() => Navigate("/")}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            Navigate("/");
          }
        }}
      >
        <img
          src={userType === "ford" ? fordLogo : lincolnLogo}
          width={getWidth(userType)}
          height={65}
          className="items-center my-auto"
          alt="logo"
          data-testid="logo"
        />
      </button>
    );
  };

  const renderHeader = () => {
    if (!profile) return <></>;

    const dealerShip = dealerShipList?.find(
      ({ dealerCode }) => dealerCode === code
    );

    return (
      <>
        <div className="h-[64px] w-full bg-[#F0F0F0] flex justify-start">
          <div className="h-full flex flex-col justify-center text-[#00095B] text-[12px] font-FordF1Regular mx-3">
            View Data as:
          </div>
          <div
            className="h-full flex flex-col justify-center"
            onClick={() => {
              setDealerCodeMenu((prevDealerCodeMenu) => !prevDealerCodeMenu);
            }}
          >
            <div className="border border-[#808080] w-[193px] text-left p-2 rounded-lg flex justify-between align-middle text-[14px] text-[#000000] font-FordF1Regular">
              <span>{selectedBrand}</span>
              <div className="h-full flex flex-col justify-center">
                <svg
                  width="16"
                  height="9"
                  viewBox="0 0 16 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M8 8.80001L15.2 1.60001L14.36 0.76001L8 7.12001L1.64 0.76001L0.799995 1.60001L8 8.80001Z"
                    fill="#333333"
                  />
                </svg>
              </div>
            </div>
          </div>
        </div>
        <div className="flex w-full bg-[#fff]  px-8 box-border no-wrap py-2">
          <div className="hidden md:flex align-middle">{renderLogo()}</div>
          <div className="hidden md:block m-auto ml-28 flex-1 text-[26px]">
            <h1 className="text-xl md:text-3xl text-[#4D4D4D] font-normal font-FordF1Medium">
              Dealer<span className="font-FordF1Bold"> HUB</span>
            </h1>
          </div>
          <div className="hidden md:block ml-auto p-2 cursor-pointer mx-2">
            <div className="text-right text-fordBlue font-FordF1Regular text-[12px]">
              <h3>{getName(dealerShip as DealerShip, name, false)}</h3>
              <h3>Sales Code: {code}</h3>
            </div>
          </div>
          <div className="fixed left-12 md:left-24 top-14 z-50">
            {canOpenDealerCodeMenu ? (
              <SwitchDealer
                codes={salesCodes?.length ? salesCodes : [code]}
                dealerShipList={dealerShipList as DealerShip[]}
                setCanOpenDealerCodeMenu={setDealerCodeMenu}
              />
            ) : (
              ""
            )}
          </div>
          <div
            className="hidden md:block cursor-pointer my-auto"
            onClick={() => setLogoutMenu((prevLogoutMenu) => !prevLogoutMenu)}
            data-testid="avatar-button"
          >
            <i className="pi pi-user" style={{ fontSize: "2rem" }}></i>
            {!logoutMenu ? (
              <i
                className="pi pi-angle-down font-FordF1Light"
                style={{ fontSize: "1.5rem" }}
              ></i>
            ) : (
              <i
                className="pi pi-angle-up font-FordF1Light"
                style={{ fontSize: "1.5rem" }}
              ></i>
            )}
          </div>
          <div className="fixed right-4 top-32 z-50">
            {logoutMenu ? <Logout setLogoutMenu={setLogoutMenu} /> : ""}
          </div>
        </div>
        <div className="hidden md:block">
          <NavBar navType="DESKTOP" setCanOpenSideNav={setCanOpenSideNav} />
        </div>
        <div className="flex w-full bg-[#fff] px-4 box-border no-wrap shadow-md">
          <div className="block md:hidden scale-90">
            {renderLogo()}
            <h1 className="text-sm text-[#000] font-FordF1Bold opacity-60">
              Dealer HUB
            </h1>
          </div>
          <div
            className="sidePannel block md:hidden"
            onClick={() =>
              setCanOpenSideNav((prevCanOpenSideNav) => !prevCanOpenSideNav)
            }
          >
            <div data-testid="sidePannel">
              <i
                className="pi pi-bars"
                style={{ fontSize: "2rem", color: "#00095b" }}
              ></i>
              <h1 className="text-sm text-fordBlue font-normal font-FordF1Bold scale-75">
                Menu
              </h1>
            </div>
          </div>
        </div>
        {canOpenSideNav ? (
          <div className="block md:hidden">
            <NavBar navType="MOBILE" setCanOpenSideNav={setCanOpenSideNav} />
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };
  return <header>{renderHeader()}</header>;
};

export default Header;
