import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";
import { Constants } from "../Constants";

const AdminOnlyRoute = React.memo(({ children }) => {
  const admins = [
    "jj31",
    "psandhy4",
    "hjnarth",
    "rkaarthi",
    "bjones47",
    "ssubra30",
    "nallen30",
    "mgariepy",
    "ngirish",
  ];
  const location = useLocation();
  const keys = [Constants.ID_TOKEN, Constants.ACCESS_TOKEN];
  const values = keys.map((key) => window.sessionStorage.getItem(key));

  if (values.includes(null)) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  const userData = jwtDecode(values[1]);
  if (admins.includes(userData?.CommonName?.toLowerCase())) {
    return children;
  } else {
    return <Navigate to="/not-found" />;
  }
});

export default AdminOnlyRoute;
