import React from "react";
import { useFavContext } from "../../context/FavContextProvider";
import { getWidth } from "../../utils/CommonUtils";
import { Constants } from "../../Constants";
import fordLogo from "./../../../src/assets/images/Ford-logo-white.svg";
import lincolnLogo from "./../../../src/assets/images/Lincoln-Star-logo-white.svg";

const Footer: React.FC = () => {
  const { profile, code, setViewFeedBack } = useFavContext();

  const renderFooter = () => {
    if (profile) {
      const userType = code[0].toLowerCase() === "f" ? "ford" : "lincoln";
      return (
        <div className="bg-[#00095B] p-4 w-full">
          <div className="w-full text-right text-white font-FordF1Medium">
            <button onClick={() => setViewFeedBack(true)}>Feedback</button>
          </div>
          <div className="flex flex-wrap justify-center md:flex-nowrap text-center md:text-left">
            <div className="w-full mx-auto text-center flex flex-col justify-center lg:block lg:w-[10%]">
              <img
                src={userType === "ford" ? fordLogo : lincolnLogo}
                width={getWidth(userType)}
                alt="fordLogo"
              />
              <h1 className="text-[#fff] font-FordF1Regular text-left">
                Dealer<span className="font-FordF1Bold"> HUB</span>
              </h1>
            </div>
            <div className="text-white text-center flex flex-wrap md:flex-nowrap justify-center align-middle my-auto w-full">
              <a
                href={Constants.TERMS_AND_CONDITIONS}
                target="_blank"
                rel="noopener noreferrer"
                className="mx-2"
              >
                Terms and Conditions
              </a>
              <span className="inline-block mx-2">|</span>
              <a
                target="_blank"
                href="https://www.ford.com/help/privacy/globalb2b/"
                className="mx-2"
              >
                Privacy Policy
              </a>
              <p className="font-FordF1SemiBold mx-2">
                ©2024 Ford Motor Company
              </p>
            </div>
          </div>
        </div>
      );
    }
    return <></>;
  };
  return <footer>{renderFooter()}</footer>;
};
export default Footer;
