import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Constants } from "../Constants";

const ProtectedRoute = React.memo(({ children }) => {
  const location = useLocation();
  const accessToken = window.sessionStorage.getItem(Constants.ACCESS_TOKEN);
  if (!accessToken) {
    return <Navigate to="/" />;
  }
  return children;
});

export default ProtectedRoute;
