import { useQuery, useMutation } from "@tanstack/react-query";
import { Component } from "../datamodels/FavouriteModel";
import { GetBearerToken } from "../utils/GetBearerToken";
import api from "../store/api";
const baseURL = "api/v1/dealer/favourites";

// Get Favourites
export const useGetFavourites = (code: string) => {
  const {
    data: favouriteData,
    isLoading,
    error,
    refetch, // Add the refetch function here
  } = useQuery<Component[], Error>({
    queryKey: ["favourites", code],
    queryFn: async () => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.get(`${baseURL}?userId=${code}`, {
          headers: { Authorization: `Bearer ${bearer_token}` },
        });
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        if (error.response.status === 401) {
          throw new Error(error.response.message);
        }
        throw new Error(error.response.message);
      }
    },
    enabled: !!code,
    staleTime: 1000 * 60 * 60 * 4, // 4 hours
  });

  return { favouriteData, isLoading, error, refetch }; // Return refetch
};

// Add Favourites
export const useAddFavourites = (refetchFavourites: () => void) => {
  // Accept refetch function as a parameter
  const { mutateAsync: addFavourite, error } = useMutation<
    Component[],
    Error,
    { code: string | undefined; name: string }
  >({
    mutationFn: async ({ code, name }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.post(
          `${baseURL}/add?userId=${code}`,
          { name, bookmarked: true },
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites(); // Call the refetch function after success
    },
  });

  return { addFavourite, error };
};

// Update Favourite
export const useUpdateFavourite = (refetchFavourites: () => void) => {
  // Accept refetch function as a parameter
  const { mutateAsync: updateFavourite, error } = useMutation<
    Component[],
    Error,
    { name: string; code: string; id: string; bookmarked: boolean }
  >({
    mutationFn: async ({ name, code, id, bookmarked }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.put(
          `${baseURL}/update?userId=${code}`,
          { id, bookmarked, name },
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites(); // Call the refetch function after success
    },
  });

  return { updateFavourite, error };
};

// Re-arrange Favourites
export const useReArrangeFavourites = (refetchFavourites: () => void) => {
  // Accept refetch function as a parameter
  const { mutateAsync: reArrangeFavourites, error } = useMutation<
    Component[],
    Error,
    { code: string; reOrderedIds: Component[] }
  >({
    mutationFn: async ({ code, reOrderedIds }) => {
      try {
        const bearer_token = await GetBearerToken();
        const response = await api.put(
          `${baseURL}/rearrange?userId=${code}`,
          { dealerFavourites: reOrderedIds },
          {
            headers: { Authorization: `Bearer ${bearer_token}` },
          }
        );
        return response?.data?.data?.dealerFavourites;
      } catch (error: any) {
        throw new Error(error.response.message);
      }
    },
    onSuccess: () => {
      refetchFavourites(); // Call the refetch function after success
    },
  });

  return { reArrangeFavourites, error };
};
