import "./FlashScreen.css";
import fordLogo from "./../../../src/assets/images/ford-logo-blue.svg";
import lincolnLogo from "./../../../src/assets/images/lincoln-logo-dark.svg";

const FlashScreen: React.FC = () => (
  <div className="content z-[1000] flex flex-col vh-[100] justify-center">
    <div className="logo-container flex  box-border no-wrap justify-center">
      <img src={fordLogo} className="logo mr-0" alt="Ford-logo" />
      <div className="logo-separator-flashScreen"></div>
      <img src={lincolnLogo} className="logo ml-0" alt="Lincoln-logo" />
    </div>
    <div className="app">
      <h1 className="dealer text-fordSkyView1200 font-semibold">
        Dealer <span className="font-bold text-xxx-large">HUB</span>
      </h1>
    </div>
  </div>
);

export default FlashScreen;
