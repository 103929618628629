interface SalesType {
  [key: string]: string[];
}
const MONTHS = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
const SALES_TYPE: SalesType = {
  dailySales: [
    "Daily Ranking",
    "Daily",
    "Sales Ranking",
    "MTD",
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "Fdaf",
    "Ranking",
    "Sales",
  ],
  monthlySales: [
    "Monthly Sales",
    "Sales Ranking",
    "MTD",
    "YTD",
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "Fdaf",
    "PY ME Sales",
    "PY ME YTD Sales",
    "Ranking",
    "Sales",
    "+/- PY",
    "% PY",
  ],
  dealerObjectives: [
    "Objective",
    "Monthly Sales",
    "% to Goal",
    "Region % to Goal",
    "Nation % to Goal",
    "Dealer Objectives",
    "Region",
    "Nation",
  ],
  vehicleLineRanking: [
    "National",
    "Market",
    "Region",
    "Zone",
    "State",
    "Fdaf",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
    "VLR",
    "Vehicle Line Ranking",
  ],
  salesVelocityAnalysis: [
    "Sales Velocity Analysis",
    "Overall",
    "Dealership",
    "Current",
    "SVA",
    "National",
    "Regional",
    "Dealership",
  ],
  vehicleLine: [
    "Sales Velocity Analysis",
    "SVA",
    "Dealership",
    "Current",
    "Vehicle Line",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
    "National",
    "Regional",
    "Dealership",
  ],
  dealerInventory: [
    "Dealer Inventory",
    "Model Year",
    "Vehicle Line",
    "Days in Stock",
    "Order Type",
    "Stock",
    "Bronco",
    "Bronco Sport",
    "Eco online",
    "Eco Sport",
    "Edge",
    "Escape",
    "Expedition",
    "Explorer",
    "F150 Total",
    "F150 Lightning",
    "F150 Gas",
    "Super Duty Total",
    "Medium Truck",
    "Maverick",
    "Mustang",
    "MustangMachE",
    "Ranger",
    "Transit Connect Total",
    "E-Transit",
    "Transit Gas",
    "Transit Na",
  ],
  fsaRecalls: [
    "All Status",
    "In System",
    "DealerStock",
    "InTransit",
    "Order Status",
    "Recall Types",
    "fsaRecalls",
  ],
  closedrecall: [
    "FSA Closed Recalls",
    "closed recall",
    "recall",
    "Last 1 day",
    "Last 3 days",
    "Last 7 days",
  ],
  gSalesVehicles: [
    "G-Sales Vehicles",
    "gsales",
    "Sales (Vehicles)",
    "Out-of-Service Units Sold to End User",
    "VIN",
  ],
  labourhoursold: ["Labour hours sold", "Regional Rank", "Labor Hours Sold"],
  bbt: [
    "Battery, Brakes and Tires",
    "Battery",
    "Brakes",
    "Tires",
    "Penetration",
    "Rank",
    "Coding",
    "Rank",
  ],
};

const Constants = {
  BACKEND_URL: process.env.REACT_APP_BACKEND_URL,
  WEB_RESOURCE: process.env.REACT_APP_WEB_RESOURCE,
  WEB_CLIENT_ID: process.env.REACT_APP_WEB_CLIENT_ID,
  AUTHORIZATION_URL: process.env.REACT_APP_AUTHORIZATION_URL,
  CHATBOT_URL: process.env.REACT_APP_CHATBOT_URL,
  DIALOGFLOW_CX_PROJECT_ID: process.env.REACT_APP_DIALOGFLOW_CX_PROJECT_ID,
  DIALOGFLOW_CX_AGENT_ID: process.env.REACT_APP_DIALOGFLOW_CX_AGENT_ID,
  OM_GSALES_URL: process.env.REACT_APP_OM_GSALES_URL,
  OM_TOPVOLUME_URL: process.env.REACT_APP_OM_TOPVOLUME_URL,
  ADFS_CODE: "code",
  BEARER_TOKEN: "bearer_token",
  ID_TOKEN: "id_token",
  CLIENT_CREDS: "client_creds",
  ACCESS_TOKEN: "access_token",

  HOME_ROUTE: "/",
  SALES_ROUTE: "/sales",
  ERROR_ROUTE: "/error",
  UNAUTHORIZED_ROUTE: "/unauthorized",
  PARTS_SERVICE_ROUTE: "/parts-service",
  FINANCE_INSURANCE_ROUTE: "/finance-insurance",
  CUSTOMER_EXPERIENCE_ROUTE: "/customer-experience",
  COMMERCIAL_ROUTE: "/commercial",
  OPERATIONS_ROUTE: "/operations",
  FEEDBACK_ROUTE: "/feedback",
  VIEW_FEEDBACK_ROUTE: "/view-feedback",
  SUBSCRIPTIONS: "/subscriptions",
  TERMS_AND_CONDITIONS: "termsAndConditions",
  DEALER: "DEALER",
  TOPVOLUME_TOP_RANK_TITLE: "Top 100 Volume Rank",
  TOPVOLUME_REGION_RANK_TITLE: "Regional Wild Card Rank",
  TOPVOLUME_GROUP_RANK_TITLE: "Dealer Group Ranking",
  TOPVOLUME_CYTD_TITLE: "CYTD Sales Count",
  VEHICLE_LINE_FORD: [
    { Bronco: "Bronco" },
    { "Bronco Sport": "Bronco Sport" },
    { "E-Series": "Econoline" },
    { EcoSport: "EcoSport" },
    { Edge: "Edge" },
    { Escape: "Escape" },
    { "Exped Total": "Expedition" },
    { Explorer: "Explorer" },
    { F150: "F150 Total" },
    { "F150 EV": "F150 Lightning" },
    { "F150 Gas": "F150 Gas" },
    { "F250-F600": "Super Duty Total" },
    { "F650-F750": "Medium Truck" },
    { Maverick: "Maverick" },
    { Mustang: "Mustang" },
    { MustangMachE: "MustangMachE" },
    { Ranger: "Ranger" },
    { "Trans Conn": "Transit Connect Total" },
    { "TransVan EV": "E-Transit" },
    { "Transit Gas": "Transit Gas" },
    { "Transit NA": "Transit NA" },
  ],
  VEHICLE_LINE_LINCOLN: [
    { Aviator: "Aviator Total" },
    { Corsair: "Corsair Total" },
    { Nautilus: "Nautilus" },
    { "Navig Tot": "Navigator Total" },
  ],
  VEHICLE_LINE_RANKING_FORD: [
    { Bronco: "Bronco" },
    { "Bronco Sport": "Bronco Sport" },
    { "E-Series": "Econoline" },
    { EcoSport: "EcoSport" },
    { Edge: "Edge" },
    { Escape: "Escape" },
    { "Exped Total": "Expedition" },
    { Explorer: "Explorer" },
    { F150: "F150 Total" },
    { "F150 EV": "F150 Lightning" },
    { "F150 Gas": "F150 Gas" },
    { "F250-F600": "Super Duty Total" },
    { "F650-F750": "Medium Truck" },
    { Maverick: "Maverick" },
    { Mustang: "Mustang" },
    { MustangMachE: "MustangMachE" },
    { Ranger: "Ranger" },
    { "Trans Conn": "Transit Connect Total" },
    { "TransVan EV": "E-Transit" },
    { "Transit Gas": "Transit Gas" },
    { "Transit NA": "Transit NA" },
  ],
  VEHICLE_LINE_RANKING_LINCOLN: [
    { Aviator: "Aviator" },
    { Corsair: "Corsair" },
    { Nautilus: "Nautilus" },
    { "Navig Tot": "Navigator" },
  ],
  VEHICLE_TYPE: [
    { RETAILANDFLEET: "Fleet&Retail" },
    { RETAIL: "Retail" },
    { FLEET: "Fleet" },
  ],
  RANKING_LEVELS_FORD: [
    { NATIONAL: "National" },
    { MARKETAREA: "Market" },
    { REGION: "Region" },
    { ZONE: "Zone" },
    { STATE: "State" },
    { FDAF: "Fdaf" },
  ],
  RANKING_LEVELS_LINCOLN: [
    { NATIONAL: "NATIONAL" },
    { MARKETAREA: "MARKET" },
    { ZONE: "ZONE" },
    { STATE: "STATE" },
  ],
  DOWNLOADITEMS: ["EXCEL", "PDF"],
  DIALOG_FLOW_JSScript:
    "https://www.gstatic.com/dialogflow-console/fast/df-messenger/prod/v1/df-messenger.js",
  MONTHS,
};

export { Constants };
